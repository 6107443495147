import React from 'react';
import {Link} from 'gatsby';
import {FooterMenuWrap, FooterMenuList} from './footer-menu.stc'

const FooterMenu = () => {
    return (
        <FooterMenuWrap>
            <FooterMenuList>
                <Link to="/blog" className="no-cursor">Blog</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/company" className="no-cursor">Histoire</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/project" className="no-cursor">Vos projets</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/contact" className="no-cursor">Contact</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/mentions" className="no-cursor">Mentions légales</Link>
            </FooterMenuList>
            <FooterMenuList>
                <a href="https://www.jardipiscines.fr/admin/" className="no-cursor" target="_blank" rel="noopener noreferrer">Admin</a>
            </FooterMenuList>
        </FooterMenuWrap>
    )
}

export default FooterMenu;